<template>
  <div class="page-wrapper-table my-pending-booking-table">
    <div class="page-wrapper-table-header">
      <div class="page-wrapper-table-header-left">
        <base-input
          v-model="query"
          type="search"
          class="search"
          prepend-icon="far fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />

        <!-- <base-input>
          <el-select v-model="selectedPeriod">
            <el-option
              v-for="period in periodOptions"
              :key="period"
              :value="period"
              :label="$t(`SALES_ORDERS.ORDER_PERIOD_${period}`)"
            />
          </el-select>
        </base-input> -->
      </div>
      <div class="page-wrapper-table-header-right">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="page-wrapper-table-body">
      <div class="bookings-list">
        <div v-if="onlyCurrentUserBookings" class="bookings-list-header">
          <div class="card">
            <div class="card-header">
              <span>
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM11 15.5H9V13.5H11V15.5ZM11 11.5H9V5.5H11V11.5Z"
                    fill="#f2bc5c"
                  />
                </svg>
                Important
              </span>
              <button
                @click="warning = false"
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i class="fal fa-times"></i>
                </span>
              </button>
            </div>
            <div class="card-body">
              <p>{{ $t("COMMON.WARNING_BOOKING_DRAFT") }}</p>
            </div>
          </div>
        </div>
        <div class="bookings-list-header">
          <h3>{{ $t("BOOKINGS.TOTAL_BOOKINGS", { total: total }) }}</h3>
        </div>
        <div class="bookings-list-content text-center" v-if="loading">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div class="bookings-list-content">
          <order-card
            v-for="order in salesOrders"
            :key="order.id"
            :order="order"
            :is-owner="onlyCurrentUserBookings"
            @onConfirmOrder="confirmOrder"
            @onDeleteOrder="deleteOrder"
            @onEditBooking="editBooking"
            @onNewBooking="newBooking"
          />
        </div>
      </div>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { Tabs, TabPane, Select, Option } from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import { BasePagination } from "@/components";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { ORDER_STATUS_DRAFT } from "@/constants/orders";
import OrderCard from "./OrderCard.vue";
import moment from "moment";
import {
  ORDER_PERIOD_CURRENT_MONTH,
  ORDER_PERIOD_LAST_THREE_MONTH,
  ORDER_PERIOD_LAST_SEVEN_DAYS,
  ORDER_PERIOD_CURRENT_WEEK,
  ORDER_PERIOD_YESTERDAY,
  ORDER_PERIOD_TODAY,
  ORDER_PERIOD,
} from "@/constants/salesOrder";
import { mapGetters } from "vuex";

export default {
  name: "pending-bookings",

  components: {
    OrderCard,
    BasePagination,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    onlyCurrentUserBookings: {
      type: Boolean,
      default: false,
      description: "display only my bookings",
    },
  },

  data() {
    return {
      query: null,
      total: 0,
      salesOrders: [],
      loading: true,
      selectedLocations: null,
      selectedPeriod: ORDER_PERIOD_LAST_SEVEN_DAYS,
      periodOptions: ORDER_PERIOD,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
    };
  },

  created() {
    this.getList();
  },

  computed: {
    ...mapGetters("profile", { me: "me" }),
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedPeriod: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 500),

    async getList(p = null) {
      try {
        this.loading = true;
        let params = {
          filter: {
            status: ORDER_STATUS_DRAFT,
            // created_at: this.getSelectedDateRange(),
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: [
            "itemsOthersFees",
            "itemsBookings.salesOrderable",
            "itemsBookings.salesOrderable.spot",
            "itemsBookings.salesOrderable.spot.category",
            "itemsBookings.salesOrderable.spot.spotType",
            "updatedBy",
            "recipient",
          ].join(","),
        };

        if (this.onlyCurrentUserBookings) {
          params = {
            ...params,
            filter: { ...params.filter, updated_by: this.me.id },
          };
        }

        if (!this.onlyCurrentUserBookings) {
          params = {
            ...params,
            filter: { ...params.filter, not_updated_by: this.me.id },
          };
        }

        if (this.globalOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.globalOrganization.id,
            },
          };
        }

        await this.$store.dispatch("salesOrders/list", params);
        this.salesOrders = this.$store.getters["salesOrders/list"];
        this.total = this.$store.getters["salesOrders/listTotal"];
      } catch (error) {
        console.warn(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }

      this.loading = false;
    },

    // getSelectedDateRange() {
    //   if (this.selectedPeriod === ORDER_PERIOD_TODAY) {
    //     return [
    //       moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    //       moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    //     ];
    //   }

    //   if (this.selectedPeriod === ORDER_PERIOD_YESTERDAY) {
    //     return [
    //       moment()
    //         .startOf("day")
    //         .subtract(1, "days")
    //         .format("YYYY-MM-DD HH:mm:ss"),
    //       moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    //     ];
    //   }

    //   if (this.selectedPeriod === ORDER_PERIOD_CURRENT_WEEK) {
    //     return [
    //       moment().startOf("week").format("YYYY-MM-DD HH:mm:ss"),
    //       moment().endOf("week").format("YYYY-MM-DD HH:mm:ss"),
    //     ];
    //   }

    //   if (this.selectedPeriod === ORDER_PERIOD_LAST_SEVEN_DAYS) {
    //     return [
    //       moment()
    //         .startOf("day")
    //         .subtract(7, "days")
    //         .format("YYYY-MM-DD HH:mm:ss"),
    //       moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    //     ];
    //   }

    //   if (this.selectedPeriod === ORDER_PERIOD_CURRENT_MONTH) {
    //     return [
    //       moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
    //       moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
    //     ];
    //   }

    //   if (this.selectedPeriod === ORDER_PERIOD_LAST_THREE_MONTH) {
    //     return [
    //       moment()
    //         .startOf("month")
    //         .subtract(2, "months")
    //         .format("YYYY-MM-DD HH:mm:ss"),
    //       moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
    //     ];
    //   }
    // },
    confirmOrder(order) {
      this.$emit("onConfirmOrder", order);
    },
    deleteOrder(order) {
      this.$emit("onDeleteOrder", order);
    },
    editBooking(order, booking) {
      this.$emit("onEditBooking", order, booking);
    },
    newBooking(customer) {
      this.$emit("onNewBooking", customer);
    },
  },
};
</script>
