<template>
  <div class="order-item" style="display: flex">
    <div class="order-item__left">
      <div class="order-item__left-header">
        <div class="title">
          <span class="title-tag">
            {{ $t("BOOKINGS.BOOKING_IDENTIFIER", { code: order.code }) }}
          </span>
          <el-tag type="warning">
            {{ $t(`SALES_ORDERS.ORDER_STATUS_${order.status}`) }}
          </el-tag>
        </div>
        <div class="info">
          <ul>
            <li>{{ $t("COMMON.CUSTOMER") }} :</li>
            <li>
              <span v-if="order.recipient">
                {{ order.recipient.firstname }}
                {{ order.recipient.lastname }}
              </span>
            </li>
          </ul>
          <ul>
            <li v-if="receptionistInCharge">
              {{ $t("SALES_ORDERS.RECEPTIONIST_IN_CHARGE") }} :
            </li>
            <li>
              <span v-if="receptionistInCharge">
                {{ receptionistInCharge }}
              </span>
              <span v-else>{{ $t("BOOKINGS.CREATED_ON_PORTAL") }}</span>
            </li>
          </ul>
        </div>
      </div>
      <booking-card
        v-for="(item, i) in order.itemsBookings"
        :key="i"
        :orderItem="item"
        :booking="item.salesOrderable"
        @onEditBooking="() => editBooking(order, item.salesOrderable)"
      />
    </div>
    <div class="order-item__right">
      <el-button
        v-if="isOwner && order.recipient"
        type="primary"
        @click="() => confirmOrder(order)"
      >
        {{ $t("BOOKINGS.CONFORM_PAYMENT") }}
      </el-button>
      <el-button
        v-if="!isOwner || !order.recipient"
        type="primary"
        @click="() => editBooking(order, order.itemsBookings[0].salesOrderable)"
      >
        {{ $t("COMMON.COMPLETE") }}
      </el-button>
      <el-button
        v-if="isOwner"
        type="secondary"
        @click="() => newBooking(order)"
      >
        {{ $t("BOOKINGS.ADD_BOOKING") }}
      </el-button>
      <el-button type="secondary" @click="() => deleteOrder(order)">
        {{ $t("COMMON.CANCEL") }}
      </el-button>
      <div class="summary">
        <div class="section">
          <dl class="row subtotal">
            <dt>{{ $t("COMMON.SUBTOTAL") }}</dt>
            <dd>{{ $formatCurrency(order.pricing.subtotal) }}</dd>
          </dl>
          <dl
            class="row"
            v-for="(tax, key) in order.pricing.taxes.details"
            :key="key"
          >
            <dt>{{ tax.name }}</dt>
            <dd>{{ $formatCurrency(tax.amount) }}</dd>
          </dl>
          <dl class="row">
            <dt>{{ $t("COMMON.TOTAL_TAXES") }}</dt>
            <dd>{{ $formatCurrency(order.pricing.taxes.total ?? 0) }}</dd>
          </dl>
        </div>
        <div class="section total">
          <dl class="row">
            <dt>
              <b>{{ $t("COMMON.TOTAL") }}</b>
            </dt>
            <dd>{{ $formatCurrency(order.pricing.total) }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Tag, Button } from "element-ui";
import BookingCard from "./BookingCard.vue";

export default {
  name: "order-card",
  components: {
    BookingCard,
    [Tag.name]: Tag,
    [Button.name]: Button,
  },
  props: {
    order: {
      type: Object,
      default: null,
      description: "SalesOrder object",
    },
    isOwner: {
      type: Boolean,
      default: false,
      description: "is owner of the order",
    },
  },
  created() {
    if (this.order.package != null) {
      this.order.pricing.total =
        this.order.pricing.total + this.order.package.price;
    }
  },
  data() {
    return {};
  },
  methods: {
    confirmOrder(order) {
      this.$emit("onConfirmOrder", order);
    },
    deleteOrder(order) {
      this.$emit("onDeleteOrder", order);
    },
    editBooking(order, booking) {
      this.$emit("onEditBooking", order, booking);
    },
    newBooking(order) {
      this.$emit("onNewBooking", order.recipient);
    },
  },

  computed: {
    /**
     * Renvoie le Prénom + Nom du réceptioniste en charge de la réservation.
     *
     * Soit il s'agit de celui qui l'a mise à jour soit de celui qui l'a créée.
     */
    receptionistInCharge() {
      if (this.order.updatedBy) {
        return `${this.order.updatedBy.firstname} ${this.order.updatedBy.lastname}`;
      }
      if (this.order.createdBy) {
        return `${this.order.createdBy.firstname} ${this.order.createdBy.lastname}`;
      }
      return null;
    },
  },
};
</script>
